<!-- Settings -->
<template>
  <div  v-loading="loading">
  <div class="card card-custom">
    <div class="card-body p-0">        
        <div class="row">           
          <div class="col-sm-6 col-xs-12">
            <b-form-group>
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                  v-on:keyup="fetchData(1)"
                ></b-form-input>
                <span class="search-icon" v-if="filter" id="clear-button">
                   <i class="fas fa-times" @click="filter =''"></i>
                   <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                </span>
               <b-input-group-append>
                  <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
         
          <div class="col-sm-6 col-xs-12 text-right">
            
              <b-button class="btn-sm btn-filter" v-b-toggle.collapse-1 variant="primary" id="tooltip-button-variant"><i class="fa fa-filter" aria-hidden="true"></i></b-button>
             <b-tooltip target="tooltip-button-variant" variant="primary">Column Filter</b-tooltip>
          </div>
          
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 select-website">
          <b-collapse id="collapse-1" class="mb-3">
            <b-card>
              <p class="card-text">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Select All</el-checkbox>
              </p>
              <hr/>
              <el-checkbox-group v-model="checkedColumns" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="(column,index) in column_list" :label="column.value" :key="index">{{column.name}}</el-checkbox>
              </el-checkbox-group>
            </b-card>
          </b-collapse>           
        </div>   
      </div>
        <div class="row">
        <div class="col-sm-12">
          <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">              
            <el-table-column label="Order #" sortable width="120" >
              <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.id)">{{(scope.row.order_number) ? scope.row.order_number : ""}}</a></el-tag>                
              </template>
            </el-table-column>  
              <el-table-column label="Order Date"  sortable prop="created_date"  width="120" v-if="showAndHideColumn('created_date')">
              <template slot-scope="scope">
              {{scope.row.created_date|formatDate('DD MMM YYYY')}} 
              </template>
            </el-table-column>           
            <el-table-column label="Company Name" prop="customer_name" sortable min-width="200" v-if="showAndHideColumn('customer_name')">
              <template slot-scope="scope">
                <el-tag slot="reference" size="mini">
                  <a href="#" @click.prevent="viewCustomer(scope.row.customer.id)">
                    {{scope.row.customer.company_name?scope.row.customer.company_name:scope.row.customer.first_name+' '+(scope.row.customer.last_name == null?"":scope.row.customer.last_name)}}
                  </a>
                </el-tag>
              </template>
            </el-table-column> 
             <el-table-column label=" Order Status" sortable width="150" prop = "status"  v-if="showAndHideColumn('status')">
              <template slot-scope="scope">
                <b>
                <span v-if="scope.row.order_status == 'waiting_payment'" class="red font-weight-bold">Waiting for Payment</span>
                <span v-if="scope.row.order_status == 'yet_verified'" class="red font-weight-bold">Order Preparation</span>
                <span v-if="scope.row.order_status == 'pending'" class="gray font-weight-bold">Pending</span>
                <span v-if="scope.row.order_status == 'processing'" class="active">Processing</span>
                 <span v-if="scope.row.order_status == 'pickup'" class="active">Ready to Pick Up</span>
                <span v-if="scope.row.order_status == 'on-hold'" class="gray">On Hold</span>
                <span v-if="scope.row.order_status == 'completed'" class="active">Completed</span>
                <span v-if="scope.row.order_status == 'cancelled'" class="red">Cancelled</span>
                <span v-if="scope.row.order_status == 'refund'" class="red">Refunded</span>
                <span v-if="scope.row.order_status == 'partially_refund'" class="red">Partially Refunded</span>
                <span v-if="scope.row.order_status == 'backorder'" class="red">Backorder</span>
                <span v-if="scope.row.order_status == 'partially_cancel'" class="red">Partially Cancel</span>
                <span v-if="scope.row.order_status == 'failed'" class="red">Failed</span>
                <span v-if="scope.row.order_status == 'waiting_dispatch'" class="yellow">Waiting Dispatch</span>
                <span v-if="scope.row.order_status == 'dispatched'" class="yellow">In Transit</span>
                </b>
                  </template>
            </el-table-column>
             <el-table-column label="Payment Status" width="130" align="center">
              <template slot-scope="scope">
                  <div v-html="getPaymentStatus(scope.row)"></div>
              </template>
            </el-table-column>                 
            <el-table-column label="Customer_type" prop="customer_type"  width="150" v-if="showAndHideColumn('customer_type')">
              <template slot-scope="scope">
              {{scope.row.customer_type}} 
              </template>
            </el-table-column>
             <el-table-column label="Customer P.O #" prop="customer_po"  width="150" v-if="showAndHideColumn('customer_po')">
              <template slot-scope="scope">
              {{scope.row.customer_po}}
              </template>
            </el-table-column> 
            <el-table-column label="Store" prop="store"  width="150" v-if="showAndHideColumn('store')">
              <template slot-scope="scope">
              {{scope.row.website_details?scope.row.website_details.website_name:""}}
              </template>
            </el-table-column>
            <el-table-column label="Shipping" prop="shiping_method"  width="150" v-if="showAndHideColumn('shiping_method')">
              <template slot-scope="scope">
              {{scope.row.shiping_method}} 
              </template>
            </el-table-column> 
              <el-table-column label="Ship Via" prop="ship_via"  width="120" v-if="showAndHideColumn('ship_via')">
              <template slot-scope="scope">
              {{scope.row.ship_via}} 
              </template>
            </el-table-column> 
            <el-table-column label=" Sub Total" prop="sub_total"  width="120" v-if="showAndHideColumn('sub_total')">
              <template slot-scope="scope">
              ${{ scope.row.sub_total.toLocaleString("en-US")}}
              </template>
            </el-table-column>
             <el-table-column label=" Tax Total" prop="tax_total"  width="120" v-if="showAndHideColumn('tax_total')">
              <template slot-scope="scope">
              ${{scope.row.tax_total.toLocaleString("en-US")}}
              </template>
            </el-table-column>
             <el-table-column label="Discount" prop="discount"  width="120" v-if="showAndHideColumn('discount')">
              <template slot-scope="scope">
              ${{scope.row.discount.toLocaleString("en-US")}}
              </template>
            </el-table-column>
             <el-table-column label=" Total Amount" prop="total"  width="140" v-if="showAndHideColumn('total')" align="center">
              <template slot-scope="scope">
              ${{scope.row.total.toLocaleString("en-US")}}
              </template>
            </el-table-column>
             <el-table-column label=" Due Amount" prop="total"  width="140" v-if="showAndHideColumn('due_amount')" align="center">
              <template slot-scope="scope">
                ${{scope.row.invoice?scope.row.invoice.balance_amount.toLocaleString("en-US"):'0.00'}}                
              </template>
            </el-table-column>
            
            <el-table-column label="Delivery Date" prop="delivery_date"  width="120" v-if="showAndHideColumn('delivery_date')">
              <template slot-scope="scope">
              {{scope.row.delivery_date|formatDate('DD MMM YYYY')}} 
              </template>
            </el-table-column>    
              <el-table-column fixed="right" align="center" label="Actions" width="80">
              <template slot-scope="scope">
                <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                  <i class="flaticon2-menu-1"></i>
                  <el-dropdown-menu slot="dropdown" class="action-dropdown">
                    <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>                      
                    <el-dropdown-item :command="2" v-if="scope.row.payment_status != 'paid' && scope.row.payment_status != 'partially_paid'|| scope.row.order_status == 'cancelled'"><i class="fa fa-pen"></i>Edit</el-dropdown-item>
                    <el-dropdown-item :command="9"  v-if="scope.row.order_status != 'processing'  && scope.row.payment_status == 'paid' && scope.row.order_status != 'waiting_dispatch'  && scope.row.order_status != 'dispatched'"><i class="fa fa-edit"></i>Order Preparation</el-dropdown-item>
                    <el-dropdown-item :command="6" v-if="scope.row.payment_status != 'paid' || scope.row.order_status == 'cancelled'"><i class="fas fa-money-bill-wave"></i>Pay Now</el-dropdown-item>                        
                    <el-dropdown-item :command="3" ><i class="fa fa-envelope"></i>Send Email</el-dropdown-item>
                     <el-dropdown-item :command="4" ><i class="fa fa-download"></i>Download</el-dropdown-item>                       
                     <el-dropdown-item :command="8" v-if="scope.row.invoice_id"><i class="fa fa-file"></i>View Invoice</el-dropdown-item>
                      <el-dropdown-item :command="7" v-if="scope.row.delivered_status == '1' && scope.row.order_status == 'cancelled'"><i class="fa fa-reply"></i>Return Order</el-dropdown-item>   
                     <el-dropdown-item :command="5" v-if="scope.row.order_status != 'cancelled' && scope.row.order_status != 'dispatched' && scope.row.order_status != 'completed' && scope.row.order_status !='waiting_dispatch' && scope.row.order_status !='refund'">                         
                       <span v-if="scope.row.payment_status != 'paid'"><i class="fa fa-times-circle"></i>Cancel Order</span>
                       <span v-if="scope.row.payment_status == 'paid'"><i class="fas fa-share-square"></i>Order Refund</span>
                     </el-dropdown-item>                     
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <el-pagination v-if="!loading" style="margin-top:15px;"
              background
              layout="sizes, prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="fetchData" 
              :page-count="pagination.last_page" 
              :page-sizes="[50, 100, 500, 1000]"
              :page-size="pagination.per_page"
              :current-page.sync="pagination.current_page" 
              :total="pagination.total_rows"
              >
          </el-pagination>
        </div>
      </div>      
    </div>         
<v-dialog/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {changeStatus, downloadPdf,generateInvoice} from "@/api/order";
import {getAllInvoice} from "@/api/invoice";
import SendEmail from '@/components/SendEmail';
//import GenerateInvoice from '@/components/GenerateInvoice';
import { globalEvents } from '@/utils/globalEvents';
//import RecoredPayment from '@/components/Payment/recordpayment';
import payNow from '@/components/Payment/payNow';
import {getAllCorder} from "@/api/weborder";
import _ from 'lodash';
export default {
  name: "list-order",
  data() {
    return {
      list: [],
      items: [],
      pagination:{
        total_rows: 1,
        current_page: 1,
        last_page:null,
        per_page: 50,
      },     
      view_type:'orderConfirm',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      invoice_list :[],
      Invoice:null,
    
       column_list:[
        
       
         {
          name:"Company Name",
          value:'customer_name'
        },
         {
          name:"Customer P.O #",
          value:'customer_po'
        },
         {
          name:"Customer Type",
          value:'customer_type'
        },
         {
          name:"Store",
          value:'store'
        },
         {
          name:"Shipping",
          value:'shiping_method'
        },
         {
          name:"Ship Via",
          value:'ship_via'
        },
         {
          name:"Sub Total",
          value:'sub_total'
        },
         {
          name:"Tax Total",
          value:'tax_total'
        },
         {
          name:"Discount",
          value:'discount'
        },
         {
          name:"Total Amount",
          value:'total'
        },  
         {
         name:"Due Amount",
         value:'due_amount'   
        }, 
        {
          name:"Order Date",
          value:"created_date"
        },       
        {
          name:"Delivery Date",
          value:'delivery_date'
        },
         {
          name:"Status",
          value:'status'
        },
        
      ],
      checkAll:false,
      isIndeterminate: true,
      checkedColumns:[    
          'customer_name',
          'store',
          'shiping_method',
          'total',
          'due_amount',
          'created_date',
          'delivery_date',
          'status'
         
      ]
    }
  },
  watch:{
    filter : _.debounce(function(n){
    this.loading = true;
      if (!n) {
        this.fetchData(1);
      }
    }, 200)
  },
  computed: {
  
  },
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Web Orders", route: "/web/orders" },
    ]);

    globalEvents.$on('invoiceAdded', invoice => {
       this.fetchData(1);
          if (invoice) {
              this.fetchData(1);
          }
      }); 
  },
  created(){
    this.fetchData(1);
    this.getInvoice();
  },
  methods:{
     getPaymentStatus(row){
          let date = new Date(row.created_at);
          var periods = {
              month: 30 * 24 * 60 * 60 * 1000,
              week: 7 * 24 * 60 * 60 * 1000,
              day: 24 * 60 * 60 * 1000,
              hour: 60 * 60 * 1000,
              minute: 60 * 1000
          };
          var diff = Date.now() - date.getTime();
          var status = "";
          var status_msg = "";
          if(row.payment_status === 'partially_paid'){
              status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
          }else if(row.payment_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
              if (diff > periods.month) {
                  status = Math.floor(diff / periods.month) + " month ago";
              } else if (diff > periods.week) {
                  status = Math.floor(diff / periods.week) + " week ago";
              } else if (diff > periods.day) {
                  status = Math.floor(diff / periods.day) + " days ago";
              } /*else if (diff > periods.hour) {
                  status = Math.floor(diff / periods.hour) + " hours ago";
              } else if (diff > periods.minute) {
                  status = Math.floor(diff / periods.minute) + " minutes ago";
              }*/
              status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
          }else if(row.payment_status === 'paid'){
              status_msg = '<span class="el-tag el-tag--mini el-tag--success">Paid</span>';
              if(row.invoice.term_paid_amount>0){
                  status_msg = '<span class="el-tag el-tag--mini el-tag--success">Term</span>';
              }
          }else if(row.payment_status === 'unpaid'){
              status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
          }
          if(row.order_status == 'cancelled'){
            status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Cancelled</span>';
           } 

          return status_msg;
      },
    updateStatus(supplier_id,status){
      changeStatus(supplier_id,status).then(response => {
        this.$showResponse('success', response.data.message);
      })},
        showAndHideColumn(column_name){
      return this.$isColumnShow(column_name, this.checkedColumns);
    },
    fetchData(p) {
      this.loading = true
      let params = { page: p }
      if(this.filter){
        params.filter = this.filter
      }
      if(this.sort){
        params.sort = this.sort;
      }
        if(this.pagination.per_page){
        params.per_page = this.pagination.per_page;
      }
      getAllCorder(params).then(response => {         
        this.list = response.data.data.data         
        this.pagination.current_page = response.data.data.current_page
        this.pagination.last_page = response.data.data.last_page
        this.pagination.total_rows = response.data.data.total          
        this.$set(this.list, '_showDetails', true)
        this.loading = false
        }

      )},
      handleCheckAllChange(val) {
        this.checkedColumns = [];
        if(val){
            this.column_list.map((column,index) =>{
                this.checkedColumns.push(column.value);
                console.log(index, this.checkedColumns);
            });
        }
        this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.column_list.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.column_list.length;
    },
      getcustomername(customer){
        if(customer != null){
             return customer.first_name+' '+customer.last_name;
        }           
      },
    viewOrder(order_id){
       this.$router.push({path:'/order/view/'+order_id})
    },
    viewCustomer(customer_id){
      this.$router.push({path:'/customer/view/'+customer_id});
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    handleSizeChange(per_page){
      this.pagination.per_page = per_page;
      this.fetchData(1);
    },
      createOrder(){
      this.$router.push({path:'/order/create'})
    },
    
    getInvoice(){
      this.loading = true;
       getAllInvoice().then(response => {          
        this.invoice_list = response.data.data;       
        this.loading = false;
      });
    },
   
    handleActions(evt, row)
    {
        if (evt === 1){
          this.$router.push({path:'/order/view/'+row.id})
        }
        else if (evt === 2){
          this.$router.push({path:'/order/edit/'+row.id})
        }          
        else if (evt === 3){ 
             let customer = row.customer;
             let order_id = row.id;
             let type = this.view_type;
             this.$modal.show(SendEmail, {customer, type, order_id}, 
                { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
        } else if (evt === 4){            
          this.loading=true;
          downloadPdf(row.id, this.view_type).then(response => {
            //console.log(response.data);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'order.pdf');
            document.body.appendChild(link);
            link.click();
            this.loading = false;
          }).catch(err => {
            console.log(err);
            this.loading = false;
          });
        }
        else if (evt === 5){            
          let message = 'Are you sure you want to order cancel ?';
          if(row.payment_status == 'paid'){
            message = 'Are you sure you want to order refund ?'
          }
          this.$bvModal.msgBoxConfirm(message, {
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if(value){ 
              this.$router.push({path:'/cancel/order/'+row.id})
            }else{
              return false;
            }
          })
        }
        else if (evt === 6){   
          this.loading = true;                     
            generateInvoice(row.id).then(response => {     
              this.Invoice = response.data.data   
              let credit = response.data.credit             
                this.$modal.show(payNow, {invoice:this.Invoice,credit:credit}, {
                  draggable: true,
                  transition: 'pop-out',
                  height: 'auto',
                  clickToClose: true,
                  adaptive: true,
                  width: '450px'
              });
              this.loading = false;               
            });  
        
        }else if (evt === 7){
          this.$router.push({path:'/return/order/'+row.id})            
        }else if (evt === 8){
          this.$router.push({path:'/invoice/view/'+row.id})
        }
        else if (evt === 9){
          this.$router.push({path:'/order/status/edit/'+row.id})
        }   
      },
  },
  
}
</script>